import { AuthService } from './auth.service';
import { CookieService } from 'ngx-cookie';
import { HttpClient } from '@angular/common/http';
import { ModelMenuService, MenuIterface } from './model.menu.service';
import { Injectable, Inject, PLATFORM_ID, EventEmitter } from '@angular/core';

@Injectable()
export class MenuService extends ModelMenuService {

    constructor(http: HttpClient, cookieService: CookieService, auth: AuthService, @Inject(PLATFORM_ID) platformId: Object) {
        super(http, cookieService, auth, platformId);
        this.setMenu(this.menuDefault);
    }

    public static PERMISSION_LOADED = new EventEmitter<boolean>();

    public getMenus() : void{
        let user = this.auth.getCredentials() ? this.auth.getCredentials() : null;
        user = user && user['session'] ? user['session']['usuario'] : null;
        if (user) {
            this.buscaPermissao(user['codigo']);
        }
    }   

    private menuDefault: MenuIterface[] = [{
        codigo: 1,
        grupo: 1,
        sort: 1,
        nomeGrupo: 'Manutenção',
        iconeGrupo: 'build',
        sortGroupo: 1,
        nome: 'Requisição',
        aberto: false,
        icone: '',
        link: '/pages/requisicao',
        params: {},
        bloq: false,
        acesso: false,
        hasEdit: true,
        edit: false,
        hasNew: true,
        new: false,
        hasDelete: true,
        delete: false
    },
    {
        codigo: 3,
        grupo: 2,
        nomeGrupo: 'Cadastro',
        iconeGrupo: 'list_alt',
        sortGroupo: 2,
        nome: 'Plano',
        aberto: false,
        icone: '',
        link: '/pages/plano',
        params: {},
        sort: 1,
        bloq: false,
        acesso: false,
        hasEdit: true,
        edit: false,
        hasNew: true,
        new: false,
        hasDelete: true,
        delete: false
    }, 

    { 
        codigo: 4, 
        grupo: 2, 
        nomeGrupo: 'Cadastro', 
        iconeGrupo: 'list_alt',
        sortGroupo: 2, 
        nome: 'Execução', 
        aberto: false, 
        icone: '', 
        link: '/pages/execucao', 
        params: {}, 
        sort: 2, 
        bloq: false, 
        acesso: false, 
        hasEdit: true, 
        edit: false, 
        hasNew: true, 
        new: false, 
        hasDelete: true, 
        delete: false 
    },
    { 
        codigo: 5, 
        grupo: 2, 
        nomeGrupo: 'Cadastro', 
        iconeGrupo: 'list_alt',
        sortGroupo: 2, 
        nome: 'Criticidade', 
        aberto: false, 
        icone: '', 
        link: '/pages/criticidade', 
        params: {}, 
        sort: 3, 
        bloq: false, 
        acesso: false, 
        hasEdit: true, 
        edit: false, 
        hasNew: true, 
        new: false, 
        hasDelete: true, 
        delete: false 
    },
    { 
        codigo: 8, 
        grupo: 2, 
        nomeGrupo: 'Cadastro', 
        iconeGrupo: 'list_alt',
        sortGroupo: 2, 
        nome: 'Tipo do Serviço', 
        aberto: false, 
        icone: '', 
        link: '/pages/tiposervico', 
        params: {}, 
        sort: 4, 
        bloq: false, 
        acesso: false, 
        hasEdit: true, 
        edit: false, 
        hasNew: true, 
        new: false, 
        hasDelete: true, 
        delete: false 
    },
    { 
        codigo: 9, 
        grupo: 2, 
        nomeGrupo: 'Cadastro', 
        iconeGrupo: 'list_alt',
        sortGroupo: 2, 
        nome: 'Local do Serviço', 
        aberto: false, 
        icone: '', 
        link: '/pages/localservico', 
        params: {}, 
        sort: 5, 
        bloq: false, 
        acesso: false, 
        hasEdit: true, 
        edit: false, 
        hasNew: true, 
        new: false, 
        hasDelete: true, 
        delete: false 
    },
    { 
        codigo: 6, 
        grupo: 3, 
        nomeGrupo: 'Configuração', 
        iconeGrupo: 'settings',
        sortGroupo: 3, 
        nome: 'Permissão de Acesso', 
        aberto: false, 
        icone: '', 
        link: '/pages/permissao', 
        params: {}, 
        sort: 1, 
        bloq: false, 
        acesso: false, 
        hasEdit: false, 
        edit: false, 
        hasNew: false, 
        new: false, 
        hasDelete: false, 
        delete: false 
    },
    {
        codigo: 7,
        sort: 2,
        grupo: 1,
        nomeGrupo: 'Manutenção',
        iconeGrupo: 'build',
        sortGroupo: 1,
        nome: 'Ordem de Serviço',
        aberto: false,
        icone: '',
        link: '/pages/ordem-servico',
        params: {},
        bloq: false,
        acesso: false,
        hasEdit: true,
        edit: false,
        hasNew: true,
        new: false,
        hasDelete: true,
        delete: false
    },
    {
        codigo: 10,
        sort: 3,
        grupo: 1,
        nomeGrupo: 'Manutenção',
        iconeGrupo: 'build',
        sortGroupo: 1,
        nome: 'Agenda',
        aberto: false,
        icone: '',
        link: '/pages/agenda',
        params: {},
        bloq: false, 
        acesso: false, 
        hasEdit: false, 
        edit: false, 
        hasNew: false, 
        new: false, 
        hasDelete: false, 
        delete: false 
    },
    {
        codigo: 11,
        sort: 6,
        grupo: 2, 
        nomeGrupo: 'Cadastro', 
        iconeGrupo: 'list_alt',
        sortGroupo: 2, 
        nome: 'Item ',
        aberto: false,
        icone: '',
        link: '/pages/item',
        params: {},
        bloq: false,
        acesso: false,
        hasEdit: true,
        edit: false,
        hasNew: true,
        new: false,
        hasDelete: true,
        delete: false
    },
    { 
        codigo: 12, 
        grupo: 3, 
        nomeGrupo: 'Configuração', 
        iconeGrupo: 'settings',
        sortGroupo: 3, 
        nome: 'Configuração de Campos', 
        aberto: false, 
        icone: '', 
        link: '/pages/conf-campos', 
        params: {}, 
        sort: 2, 
        bloq: false, 
        acesso: false, 
        hasEdit: false, 
        edit: false, 
        hasNew: false, 
        new: false, 
        hasDelete: false, 
        delete: false 
    },
    { 
        codigo: 13, 
        grupo: 3, 
        nomeGrupo: 'Configuração', 
        iconeGrupo: 'settings',
        sortGroupo: 3, 
        nome: 'Configurações Gerais', 
        aberto: false, 
        icone: '', 
        link: '/pages/conf-gerais', 
        params: {}, 
        sort: 3, 
        bloq: false, 
        acesso: false, 
        hasEdit: false, 
        edit: false, 
        hasNew: false, 
        new: false, 
        hasDelete: false, 
        delete: false 
    },
    {
        codigo: 14,
        sort: 8,
        grupo: 2, 
        nomeGrupo: 'Cadastro', 
        iconeGrupo: 'list_alt',
        sortGroupo: 2, 
        nome: 'Pausa',
        aberto: false,
        icone: '',
        link: '/pages/pausa',
        params: {},
        bloq: false,
        acesso: true,
        hasEdit: true,
        edit: false,
        hasNew: true,
        new: true,
        hasDelete: true,
        delete: false
    },
   ];

    public lstMenu() {
        let list = []; 
        let auxMenus = this.getMenuAcesso().filter(p => p.acesso);
        auxMenus.forEach((row) => {
            let tem = false;
            for (let i = 0; i < list.length; i++) {
                if (row['grupo'] === list[i]['grupo']) {
                    tem = true;
                }
            }
            if (!tem) {
                list.push({
                    nome: row['nomeGrupo'],
                    aberto: row['aberto'],
                    icone: row['iconeGrupo'],
                    grupo: row['grupo'],
                    links: []
                });
            }
        });
        auxMenus.forEach((row) => {
            for (let i = 0; i < list.length; i++) {
                if (row['grupo'] === list[i]['grupo']) {
                    list[i]['links'].push({
                        nome: row['nome'], link: row['link'], icone: row['icone'], params: row['params']
                    });
                }
            }
        });
        return list;
    }
}
