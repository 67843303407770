import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { empty, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(public auth: AuthService, private matDialog: MatDialog, private bottomSheet: MatBottomSheet, private snackbar: MatSnackBar) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!navigator.onLine) {
            this.snackbar.open('Verifique sua conexão com a internet!', 'X', { duration: 4000 });
            return empty();
        }

        const headers = request.headers.set('Authorization', 'Bearer ' + ((this.auth.getToken()) ? this.auth.getToken() : ''));
        const requestClone = request.clone({
            headers
        });
        return next.handle(requestClone)
        .pipe(tap((ev: HttpEvent<any>) => {
            if (ev instanceof HttpResponse) {
                const elapsed = Date.now();
            }
        }), catchError(response => {
            if (response.statusText === 'Unauthorized') {
                this.matDialog.closeAll();
                this.bottomSheet.dismiss();
                this.auth.clearCredentials();
                return throwError(response.error || response.err || response.erro || response);
            }
            return throwError(response.error || response.err || response.erro || response);
        }));
    }
}
