import {Injectable} from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse
} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable()
export class ErrosInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMessage = "Erro desconhecido";
                if (error.error instanceof ErrorEvent) {
                    // Erro do lado do cliente
                    errorMessage = `Erro: ${error.error.message}`;
                } else {
                    // Erro do lado do servidor
                    errorMessage = `Ops aconteceu um erro: Código do erro: ${error.status}, ` + `mensagem: ${error.error.message}`;
                }
                console.error(`===> Error Interceptor: `, {
                    message: errorMessage,
                    error,
                });
                return throwError(() => new Error(errorMessage));
            })
        );
    }
}
