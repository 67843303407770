import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { environment } from '../environments/environment';
import { RoutesInterceptor } from './lib/interceptor/routes.interceptor';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/notFound/notFound.component';

const routes: Routes = [
    { 
        path: 'login', 
        component: LoginComponent 
    }, 
    {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [RoutesInterceptor],
        canActivateChild: [RoutesInterceptor]
    }, 
    { 
        path: 'page-not-found', 
        component: NotFoundComponent 
    }, 
    {
        path: '',
        redirectTo: '/pages/home',
        pathMatch: 'full'
    }, 
    { 
        path: '**', 
        redirectTo: 'page-not-found' 
    }
];

const config: ExtraOptions = {
    useHash: !(environment.production)
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)], exports: [RouterModule]
})
export class AppRoutingModule {}
