import { Component } from '@angular/core';

@Component({
    selector: 'limber-not-found', 
    templateUrl: './notFound.component.html', 
    styleUrls: ['./notFound.component.scss']
})

export class NotFoundComponent {
}
