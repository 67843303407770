import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LibModule } from './lib.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { TokenInterceptor } from './lib/interceptor/token.interceptor';
import { MenuService } from './services/menu.service';
import { CookieModule, CookieService } from 'ngx-cookie';
import { RoutesInterceptor } from './lib/interceptor/routes.interceptor';
import { OverlayModule } from '@angular/cdk/overlay';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/notFound/notFound.component';
import { PermissionInterceptor } from './lib/interceptor/permission.interceptor';
import { PermissaoService } from './pages/permissao/permissao.service';
import { ConfirmService } from './lib/helpers/confirmation';
import { MatPaginatorIntlCro } from './lib/helpers/confgTextPaginator';
import { MaterialModule } from './lib/material.module';
import {ErrosInterceptor} from "./interceptor/erros.interceptor";

@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LibModule,
        MaterialModule,
        CookieModule.forRoot(),
        OverlayModule,
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        NotFoundComponent
    ],
    providers: [
        AuthService,
        MenuService,
        RoutesInterceptor,
        PermissionInterceptor,
        CookieService,
        PermissaoService,
        ConfirmService,
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
        { provide: HTTP_INTERCEPTORS, useClass: ErrosInterceptor, multi: true }],
    bootstrap: [AppComponent]
})
export class AppModule {}
