import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomDateAdapter } from './lib/helpers/custom-date-adapter';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import localePr from '@angular/common/locales/pt';
import { NgSelectModule } from '@ng-select/ng-select';
import { MaterialModule } from './lib/material.module';
import { PipesModule } from './lib/pipes/pipes.module';
import { ConfirmationDialogModule } from './lib/confirmationDialog/confirmationDialog.module';
import { MaskDateModule } from './lib/directive/maskDateDirective/maskDateDirective.module';
import { LoadingButtonModule } from './lib/loadingButton';

registerLocaleData(localePr);

const NODE_MODULE = [CommonModule, FormsModule, TextMaskModule, CurrencyMaskModule, ReactiveFormsModule, MaterialModule, NgSelectModule, ConfirmationDialogModule, MaskDateModule];

@NgModule({
    imports: [...NODE_MODULE, PipesModule,LoadingButtonModule],
    exports: [...NODE_MODULE, PipesModule,LoadingButtonModule],
    declarations: [],
    providers: [MatDatepickerModule, { provide: LOCALE_ID, useValue: 'pt-BR' }, {
        provide: DateAdapter, useClass: CustomDateAdapter
    }]
})
export class LibModule {
    constructor(private dateAdapter: DateAdapter<Date>) {
        this.dateAdapter.setLocale('pt-br');
    }

    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: LibModule
        };
    }
}
