import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class AuthService {
    constructor(private router: Router, private cookieService: CookieService) {
    }


    // Metodo que salva o token do usuario nos cokies
    public setToken(data: any) : boolean{
        if (data) {
            this.cookieService.put('token', data.token);
            return true;
        }
        return false;
    }

    // MEtodo que retorna as credencias do usuario
    public getCredentials() : any{
        let settings = this.cookieService.getObject('settings');
        if (typeof settings === 'string') {
            settings = JSON.parse(settings);
        }
        return (settings ? settings : undefined);
    }

    // Metodo que retorna o token do usuario
    public getToken() : any{
        let token = this.cookieService.get('token');
        return (token ? token : undefined);
    }

    // Metodo que retorna true caso o usuario esteja autenticado e false caso não esteje autenticado
    public isUserAuthenticated() : boolean{
        let token = this.cookieService.get('token');
        return (token ? true : false);
    }

    public setCredentials(user: any) {
        let settings : any = {
            session: {
                usuario: {
                    empresa: user.empresa,
                    estabelecimento: user.estabelecimento,
                    codigo: user.codigo,
                    nome: user.nome,
                }, datetimelastsession: new Date()
            }
        };
        this.cookieService.putObject('settings', Object(JSON.stringify(settings)));
        return true;
    }

    public clearCredentials() {
        try {
            if (this.getToken()) this.router.navigate(['./login']);
            let pdv = (this.cookieService.get('pdv') ? parseInt(String(this.cookieService.get('pdv')), 10) : null);
            localStorage.clear();
            this.cookieService.removeAll();
            this.setPdv(pdv + '');
        } catch (e) {
        }
    }

    public setPdv(pdv : string) {
        let date = new Date();
        let days = 60;
        let time = date.getTime() + (days * 86400000);
        date.setTime(time);
        this.cookieService.put('pdv', (pdv + ''), { expires: date });
    }
}

export interface Usuario {
    session: {
        datetimelastsession: Date, usuario: {}
    };
}
