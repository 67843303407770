import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialog } from '../confirmationDialog/confirmationDialog.component';

@Injectable()
export class ConfirmService {

    private options: MatDialogConfig = {
        disableClose: false, width: '450px'
    };

    constructor(private dialog: MatDialog) {
    }

    confirm(msg: string, config?: MatDialogConfig, data?: object): MatDialogRef<ConfirmationDialog> {
        const obj = (config ? config : this.options);
        obj.data = (data || {});
        const dialogRef = this.dialog.open(ConfirmationDialog, obj);
        dialogRef.componentInstance.confirmMessage = msg;
        return dialogRef;
    }

    async confirmAsync(msg: string, config?: MatDialogConfig, data?: object) {
        const obj = (config ? config : this.options);
        obj.data = (data || {});
        const dialogRef = this.dialog.open(ConfirmationDialog, obj);
        dialogRef.componentInstance.confirmMessage = msg;
        let resultConfirm = false;
        await dialogRef.afterClosed()
        .toPromise()
        .then((result) => {
            resultConfirm = result;
        });
        return resultConfirm;
    }
}
