<h1 class="color-primary text-center;" mat-dialog-title
    style="font-size: 22px;text-align: center">{{(data && data.title ? data.title : 'Confirmação')}}</h1>
<div *ngIf="confirmMessage" mat-dialog-content
     style="min-height: 66px;white-space: pre-line;text-align: center">{{confirmMessage}}</div>
<div *ngIf="(data && data['showOptions'])" mat-dialog-actions style="display: block; text-align: end;">
    <button (click)="dialogRef.close(true)" cdkFocusInitial color="primary" mat-flat-button>OK</button>
</div>

<div *ngIf="!(data && data['showOptions'])" mat-dialog-actions style="display: block; text-align: center;">
    <button (click)="dialogRef.close(false)" color="primary" mat-button>Não</button>
    <button (click)="dialogRef.close(true)" cdkFocusInitial color="primary" mat-flat-button style="margin-left: 16px;">
        Sim
    </button>
</div>
