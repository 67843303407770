import { environment } from '../environments/environment';

let link = '';

if (environment.production) {
    link = '/api/';
} else {
    link = '/api/';
}
export const API_CONFIG = {
    url: link,
};
