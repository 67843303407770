import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class MatPaginatorIntlCro extends MatPaginatorIntl {
    itemsPerPageLabel = 'Itens por página';
    firstPageLabel = 'Primeira pagina';
    lastPageLabel = 'Ultima página';
    nextPageLabel = 'Próxima página';
    previousPageLabel = 'Página anterior';
}
