import { CookieService } from 'ngx-cookie';
import { API_CONFIG } from '../app.setings';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { MenuService } from 'src/app/services/menu.service';
import { AuthService } from './auth.service';

@Injectable()
export class ModelMenuService {

    private menu: MenuIterface[] = [];

    private user;

    constructor(protected http: HttpClient, protected cookieService: CookieService, protected auth: AuthService, @Inject(PLATFORM_ID) private platformId: Object) {
        this.user = this.auth.getCredentials() ? this.auth.getCredentials() : null;
        this.user = this.user && this.user['session'] ? this.user['session']['usuario'] : null;
        if (this.user) {
            this.buscaPermissao(this.user['codigo']);
        }
    }

    public getMenu() {
        return this.menu;
    }

    public getMenuGroup() {
        return this.groupMenu();
    }

    public setMenu(itens) {
        this.menu = itens;
    }

    public getMenuAcessoGroup() {
        this.groupMenu();
    }

    public getMenuAcesso() {
        let list = JSON.parse(JSON.stringify(this.menu));
        let permissao: any = sessionStorage.getItem('permissao');
        if (typeof permissao === 'string') {
            permissao = JSON.parse(permissao);
        }
        if (permissao) {
            permissao = permissao;
            list.forEach(row => {
                for (let i = 0; i < permissao.length; i++) {
                    if (row['codigo'] === permissao[i]['codigo']) {
                        row['acesso'] = permissao[i]['acesso'];
                        row['new'] = permissao[i]['new'];
                        row['edit'] = permissao[i]['edit'];
                        row['delete'] = permissao[i]['delete'];
                    }
                }
            });
        }
        return list;
    }

    getPermissaoTela(tela, codigo?) {
        if (codigo) {
            let list = this.getMenuAcesso();
            let permissaoTela = {};
            for (let i = 0; i < list.length; i++) {
                if (list[i]['codigo'] === codigo) {
                    permissaoTela = JSON.parse(JSON.stringify(list[i]));
                }
            }
            return permissaoTela;
        } else {
            let list = this.getMenuAcesso();
            let permissaoTela = {};
            for (let i = 0; i < list.length; i++) {
                if (list[i]['link'].includes(tela)) {
                    permissaoTela = JSON.parse(JSON.stringify(list[i]));
                }
            }
            return permissaoTela;
        }
    }

    public buscaPermissao(id) {
        this.getPermissao(id)
        .then((response : any) => {
            if (response && response.length) {
                sessionStorage.setItem('permissao', JSON.stringify(response));
            } else {
                sessionStorage.setItem('permissao', JSON.stringify([]));
            }

            MenuService.PERMISSION_LOADED.emit(true);
       
        }).catch((err) => {
            if (isPlatformBrowser(this.platformId)) {
                console.log(err);
            }
        });
    }

    public getPermissao(id) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(API_CONFIG.url + 'permissao/usuario')
            .toPromise()
            .then(res => resolve(res), error => reject(error));
        });
        return promise;
    }

    private groupMenu() {
        let list = [];
        this.menu.forEach((row) => {
            let tem = false;
            for (let i = 0; i < list.length; i++) {
                if (row['grupo'] === list[i]['grupo']) {
                    tem = true;
                }
            }
            if (!tem) {
                list.push({
                    nome: row['nomeGrupo'],
                    aberto: row['aberto'],
                    icone: row['iconeGrupo'],
                    grupo: row['grupo'],
                    links: []
                });
            }
        });
        this.menu.forEach((row) => {
            for (let i = 0; i < list.length; i++) {
                if (row['grupo'] === list[i]['grupo']) {
                    list[i]['links'].push({
                        nome: row['nome'], link: row['link'], icone: row['icone'], params: row['params']
                    });
                }
            }
        });
        return list;
    }
}

export interface MenuIterface {
    nome: string;
    aberto?: boolean;
    grupo?: number;
    nomeGrupo?: string;
    iconeGrupo?: string;
    sortGroupo?: number;
    icone: string;
    link: string;
    params?: object;
    codigo: number;
    sort: number;
    bloq: boolean;
    acesso: boolean;
    hasEdit: boolean;
    edit: boolean;
    hasNew: boolean;
    new: boolean;
    hasDelete: boolean;
    delete: boolean;
}
