<div id="notfound">
    <div class="notfound w-full">
        <div class="notfound-404"></div>
        <div class="texts-notfound">
            <h1>404</h1>
            <h3>Oops! Página não encontrada</h3>
            <p>Desculpe, mas não foi possivel localizar a página que você está procurando.</p>
            <a [routerLink]="['/']">Voltar a home</a>
        </div>
    </div>
</div>
