import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG } from '../../app.setings';

@Injectable()
export class LoginService {

    constructor(private http: HttpClient) {
    }

    login(data) {
        return new Promise((resolve, reject) => this.http.post(API_CONFIG.url + 'auth/', data)
        .toPromise()
        .then(res => resolve(res), error => reject(error)));
    }

    me() {
        return new Promise((resolve, reject) => this.http.get(API_CONFIG.url + 'users/token')
        .toPromise()
        .then(res => resolve(res), error => reject(error)));
    }

    listaEmpresas() {
        return new Promise((resolve, reject) => this.http.get(API_CONFIG.url + 'auth/emp')
        .toPromise()
        .then(res => resolve(res), error => reject(error)));
    }

    listaEstabelecimentos(codEmpresa) {
        return new Promise((resolve, reject) => this.http.get(API_CONFIG.url + 'auth/est/' + codEmpresa)
        .toPromise()
        .then(res => resolve(res), error => reject(error)));
    }
}
