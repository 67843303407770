import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../../services/auth.service';

@Injectable()
export class RoutesInterceptor implements CanActivate, CanActivateChild {

    constructor(private auth: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.validatorAuthenticated();
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.validatorAuthenticated();
    }

    validatorAuthenticated(): boolean {
        let loader = document.getElementById('limber-splash-screen');
        if (loader) {
            loader.style.display = 'none';
        }
        if (this.auth.isUserAuthenticated()) {
            return true;
        } else {
            this.router.navigate(['./login']);
            return false;
        }
    }
}
