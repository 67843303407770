import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmationDialog } from './confirmationDialog.component';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
	imports: [MatButtonModule, CommonModule, MatDialogModule],
    exports: [ConfirmationDialog],
    declarations: [ConfirmationDialog],
    entryComponents: [ConfirmationDialog]
})
export class ConfirmationDialogModule {}
