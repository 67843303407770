import { LimberValidators } from './limberValidator';

export class Masks {

    public hour = [/\d/, /\d/, ':', /\d/, /\d/];
    public date = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    public cep = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    public cpf = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    public cnpj = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    private validator = new LimberValidators();

    public creditCardMask = (rawValue: string) => {
        let element = document.getElementsByClassName('jp-card-identified')[0];
        if (element) {
            let classes = element.classList;
            let brand = classes['value'].replace('ie-11', '')
            .replace(/jp-card/g, '')
            .replace(/-/g, '')
            .replace('identified', '')
            .replace('safari', '')
            .replace('ie11', '')
            .trim();
            if (brand === 'amex_credito') {
                return [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/];
            }
        }
        return [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];

    };
    public cpfOrCnpj = (rawValue: string) : any => {
        let value = rawValue.replace(/[^0-9]/g, '');
        if (!this.validator.validateCpf({ value: value })) {
            return this.cpf;
        }
        return this.cnpj;
    };

    public maskPhone = (rawValue: string) => {
        let number = rawValue.replace(/[(]|[)]| |-|_/g, '');
        let arrayNumber = [];
        if (number.length >= 3) {
            arrayNumber = number.split('');
            if (arrayNumber[2] === '9') {
                return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
            }
        }
        return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    };



    public setMaskCnpj(v){
        v=v.replace(/\D/g,"")                       
        v=v.replace(/^(\d{2})(\d)/,"$1.$2")             
        v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") 
        v=v.replace(/\.(\d{3})(\d)/,".$1/$2")          
        v=v.replace(/(\d{4})(\d)/,"$1-$2")              
        return v
    }
    
    public setMaskCpf(v){
        v=v.replace(/\D/g,"")                   
        v=v.replace(/(\d{3})(\d)/,"$1.$2")       
        v=v.replace(/(\d{3})(\d)/,"$1.$2")     
                                                
        v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
        return v
    }
}
