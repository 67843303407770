import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import * as moment from 'moment';

export class LimberValidators {

    public lessThanZero = (control: AbstractControl): ValidationErrors | null => {
        if (control.value < 0) {
            return { lessThanZero: { actual: control.value } };
        }
        return null;
    };

    public isGreaterThanZero = (control: AbstractControl): ValidationErrors | null => {
        if (control.value <= 0) {
            return { lessThanZero: { actual: control.value } };
        }
        return null;
    };

    public validateCardValidity = (control: AbstractControl): ValidationErrors | null => {
        let value: string = control.value;
        value = value.replace(/ /g, '');
        let mes = parseInt(value.split('/')[0], 10);
        if (value) {
            if ((value.trim()).length < 5) {
                return { formatInvalid: 'MM/YYYY' };
            } else if ((value.trim()).length === 6) {
                return { formatInvalid: 'MM/YYYY' };
            } else if (mes > 12 || mes < 1) {
                return { invalidMonth: true };
            } else {
                let anoAtual = new Date().getFullYear();
                let ano = parseInt(value.split('/')[1], 10);
                // let anoMin = anoAtual - 2000;
                if (ano < anoAtual) {
                    return { invalidYear: true };
                    // if ((value.trim()).length > 5 || ano < anoMin) {
                    //     return {invalidYear: true};
                    // }
                }
            }
            return null;
        }
        return { formatInvalid: 'MM/YYYY' };
    };

    public validateCnpj = (control: AbstractControl | any): ValidationErrors | null => {
        if(! control['value']){
            return null;
        }

        let b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
        let c = control['value'];
        if ((c = c.replace(/[^\d]/g, '')).length !== 14) return { cnpjInvalid: true };

        if (/0{14}/.test(c)) return { cnpjInvalid: true };

        for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]) ;
        if (c[12] != (((n %= 11) < 2) ? 0 : 11 - n)) return { cnpjInvalid: true };

        for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]) ;
        if (c[13] != (((n %= 11) < 2) ? 0 : 11 - n)) return { cnpjInvalid: true };

        return null;
    };

    public validateCpf = (control: AbstractControl | any): ValidationErrors | null => {
        if(! control['value']){
            return null;
        }

        let strCPF = (control.value).replace(/[^0-9]/g, '');
        let soma = 0;
        let resto;
        // if (strCPF === '00000000000') return { cpfInvalid: true };
        for (let i = 1; i <= 9; i++) soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
        resto = (soma * 10) % 11;
        if ((resto === 10) || (resto === 11)) resto = 0;
        if (resto !== parseInt(strCPF.substring(9, 10), 10)) return { cpfInvalid: true };
        soma = 0;
        for (let i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
        resto = (soma * 10) % 11;
        if ((resto === 10) || (resto === 11)) resto = 0;
        if (resto !== parseInt(strCPF.substring(10, 11), 10)) return { cpfInvalid: true };

        return null;
    };

    public validateCpfOrCnpj = (control: AbstractControl) => {
        if(! control.value){
            return null;
        }
        let strCPF = (control.value).replace(/[^0-9]/g, '');
        if (strCPF.length > 11) {
            return this.validateCnpj(control);
        } else {
            return this.validateCpf(control);
        }
    };

    public validadeteBirthDate = (control: AbstractControl | any): ValidationErrors | null => {
        if (!control.value) return null;
        if (moment()
        .diff(control.value, 'minutes') < 0) {
            return { invalidDate: true };
        }
        if (moment()
        .diff(control.value, 'year') > 130) {
            return { invalidDate: true };
        }

        return null;
    };

    public validateAutoComplete = (control: AbstractControl | any): ValidationErrors | null => {
        if (!control.value || (typeof control.value == 'string' && ! (control.value.trim().length > 0))) return null;
        if (typeof control.value !== 'object') {
            return { notSeleceted: true };
        } else {
            let keys = Object.keys(control.value);
            if (!(keys && keys.length)) {
                return { notSeleceted: true };
            }
        }
        return null;
    };

    public validateFieldWithMask = (control: AbstractControl | any): ValidationErrors | null => {
        if (!control.value) return null;
        if (control.value.includes('_')) {
            return { inconpletedMask: true };
        }
        return null;
    };

    public validBrand = (control: AbstractControl | any): ValidationErrors | null => {
        let brandSelect;
        switch (control.value) {
            case 'visa':
                brandSelect = 'Visa';
                break;
            case 'mastercard':
                brandSelect = 'Master';
                break;
            case 'elo':
                brandSelect = 'Elo';
                break;
            case 'dinersclub':
                brandSelect = 'Diners';
                break;
            case 'hipercard':
                brandSelect = 'Hipercard';
                break;
            case 'discover':
                brandSelect = 'Discover';
                break;
            case 'amex':
                brandSelect = 'Amex';
                break;
            case 'jcb':
                brandSelect = 'JCB';
                break;
            default:
                brandSelect = null;
                break;
        }
        if (brandSelect) {
            return null;
        }
        return { brandInvalid: true };
    };

    validateCommaSepEmail = (control: AbstractControl | any): ValidationErrors | null => {
        if (!control.value) return null;
        const emails = control.value.split(';')
        .map(e => e.trim());
        const mailInvalid = emails.some(email => Validators.email(new FormControl(email)));
        if (mailInvalid) {
            return { emailListInvalid: true };
        }
        return null;
    };
}
