import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG } from 'src/app/app.setings';
import { Observable } from 'rxjs';
import { MenuService } from 'src/app/services/menu.service';
import { Router } from '@angular/router';

@Injectable()
export class PermissaoService {

    constructor(private http: HttpClient,private menuService : MenuService,private router : Router) {}

    public save(object : any): Promise<any>  {
        return this.http.post(API_CONFIG.url + 'permissao/save',object).toPromise();
    }

    public getGrupos(filters): Observable<any> {
        return this.http.post(API_CONFIG.url + 'permissao/grupos', filters);
    }

    public getPermissoesGrupo(grupo : number): Promise<any> {
        return this.http.get(API_CONFIG.url + 'permissao/grupo/' + grupo).toPromise();
    }

    public getPermissaoPersonalizada(identificadores : string[]): Promise<any> {
        return this.http.post(API_CONFIG.url + 'permissao/personalizadas/identificador',identificadores).toPromise();
    }

    public getPermissoesPersonalizada(codigo: number,grupo : number): Promise<any> {
        return this.http.post(API_CONFIG.url + 'permissao/personalizadas',{codigo : codigo, grupo : grupo}).toPromise();
    }

    public getPermissionCrud(url : string = null){
        let link = url ? url : this.router.url;
        let menu = this.menuService.getMenuAcesso().find(p => p.link == link);

        if(menu){
            return {
                new : menu.new,
                edit : menu.edit,
                delete : menu.delete,
            };
        }

        return {
            new : false,
            edit: false,
            delete: false
        };;

    }
}
