import {
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewContainerRef
} from '@angular/core';
import { LoadingButtonComponent } from './loadingButton.component';

@Directive({
    selector: '[loadingButton]'
})
export class LoadingButtonDirective implements OnDestroy, OnChanges {
    @Input() loadingButton: boolean; // Parametro que será utilizado para valdiar se aciona o loading ou não
    private componentInstance: ComponentRef<LoadingButtonComponent> = null; // Atributo que será usado para cria o componente de loading
    private innerText = ''; // Atributo que será usado para salvar temporiatiamente o texto enquanto o loadingButton for true
    constructor(private el: ElementRef, private viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) {
    }

    // Detecta alterações
    ngOnChanges(changes: SimpleChanges) {
        if (changes.loadingButton) { // Verifica se a alteração foi na variavel loadingButton
            this.toggleLoader(changes.loadingButton.currentValue);
        }
    }

    // Metodo que retira ou colona o loading bar no botão
    toggleLoader(loading: boolean) {
        if (loading) { // se true cria o component com o loading e adciona no botão
            this.createLoaderComponent(); // Cria o compoennte de loading
            this.makeComponentAChild(); // Adiciona o compoentne de loading no botão
        } else { // senão verifica se o componente já foi criado
            if (this.componentInstance) { // se o componente de loading já foi criado uma vez remove o componente de loading do botão e adiciona novamente o texto
                this.el.nativeElement.innerText = this.innerText; // Coloca o texto novamente no botão
                this.componentInstance.destroy(); // Destroi o componente
            }
        }
    }

    ngOnDestroy(): void {
        if (this.componentInstance) {
            this.componentInstance.destroy();
        }
    }

    // Metodo que vai cria o componente de loading. selector: loading-button
    private createLoaderComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingButtonComponent);
        this.componentInstance = this.viewContainerRef.createComponent(componentFactory);
    }

    // Metodo que vai injetar o componente dentro do botão
    private makeComponentAChild() {
        const loaderComponentElement = this.componentInstance.location.nativeElement;
        const sibling: HTMLElement = loaderComponentElement.previousSibling;
        this.innerText = (this.el.nativeElement.innerText ? this.el.nativeElement.innerText : this.innerText); // Salva o texto para ser colocado novamente quando loadingButton mudar seu valor
        this.el.nativeElement.innerText = ''; // Deixa o texto vazio assim ficará apenas o loading no botão
        sibling.insertBefore(loaderComponentElement, sibling.firstChild);
    }
}
