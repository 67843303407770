import { MenuService } from '../../services/menu.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class PermissionInterceptor implements CanActivate, CanActivateChild {

    constructor(private menuService: MenuService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.validatorPermission(state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.validatorPermission(state.url);
    }

    validatorPermission(tela: any): boolean {
        tela = tela.replace(/;.*$/, '');
        let permissaoTela : any = this.menuService.getPermissaoTela(tela);
        if (permissaoTela && permissaoTela['acesso']) {
            return true;
        }else {
            return false;
        }
    }
}
