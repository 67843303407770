import { NgModule } from '@angular/core';
import { LoadingButtonDirective } from './loadingButton.directive';
import { CommonModule } from '@angular/common';
import { LoadingButtonComponent } from './loadingButton.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule],
    exports: [LoadingButtonDirective, LoadingButtonComponent],
    declarations: [LoadingButtonDirective, LoadingButtonComponent],
    entryComponents: [LoadingButtonComponent]
})
export class LoadingButtonModule {}
