import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'confirm-dialog',
    templateUrl: './confirmationDialog.component.html'

})
// tslint:disable-next-line:component-class-suffix
export class ConfirmationDialog implements OnInit {

    public confirmMessage: string;

    constructor(public dialogRef: MatDialogRef<ConfirmationDialog>, @Inject(MAT_DIALOG_DATA) public data) {
    }

    ngOnInit() {

    }
}
