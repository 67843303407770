import { Directive, ElementRef, OnDestroy } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';
import { Masks } from '../../utils/masks';

@Directive({
    selector: '[appMaskDate]'
})
export class MaskDateDirective implements OnDestroy {

    public mask = new Masks().date;
    public maskedInputController: any;

    constructor(private element: ElementRef) {
        this.maskedInputController = textMask.maskInput({
            inputElement: this.element.nativeElement,
            mask: this.mask,
            guide: true,
            showMask: false,
            keepCharPositions: true
        });
    }

    ngOnDestroy() {
        this.maskedInputController.destroy();
    }

}
