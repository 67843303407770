import { Component, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
    selector: 'limber-manutencao', 
    template: '<router-outlet></router-outlet>'
})

export class AppComponent implements OnInit {
    constructor() {
        moment.tz.setDefault('America/Bahia');
    }

    ngOnInit() {
        let loader = document.getElementById('limber-splash-screen');
        if (loader) {
            loader.style.display = 'none';
        }
    }
}
