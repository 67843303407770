import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { LoginService } from './login.service';
import { AuthService } from '../../services/auth.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
    selector: 'limber-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [LoginService]
})

export class LoginComponent implements OnInit {
    @ViewChild('loginEstab', { static: false }) nameField: ElementRef;

    public mensagem = '';
    public messageErro: string;
    public carregando = true;
    public model: any = {};
    public loading = false;
    public loginForm: FormGroup;
    public empresaForm: FormGroup;
    public divLogin = false;
    public hide = true;
    public loginError = false;
    public listaEmpresas = [];
    public listaEstabelecimentos = [];
    public selectEmpresa;
    public empresaNome;

    constructor(
        private loginService: LoginService, 
        private router: Router, 
        private menuService: MenuService,
        private authService: AuthService, 
        private formBuilder: FormBuilder) {
            
        this.loginForm = formBuilder.group({
            usuario: [null, Validators.required], senha: [null, Validators.required]
        });

        this.empresaForm = formBuilder.group({
            empresa: [null, Validators.required], estabelecimento: [null, Validators.required]
        });
    }

    ngOnInit() {
        let spinner = document.getElementById('limber-splash-screen');
        if (spinner && spinner.style) {
            spinner.style.display = 'none';
        }
        this.authService.clearCredentials();
        this.buscaEmpresas();
    }

    public onKey(event: any): void{
        if (event.key === 'Enter') {
            if (this.divLogin) {
                this.logar();
            } else {
                this.goLogin();
            }
        }
    }

    public logar(): void {
        this.mensagem = '';
        this.loading = true;

        let aux = {
            usuario: this.loginForm.value.usuario,
            senha: this.loginForm.value.senha,
            empresa: this.empresaForm.controls['empresa'].value,
            estabelec: this.empresaForm.controls['estabelecimento'].value
        };

        this.loginService.login(aux).then((response: any) => {
            this.authService.clearCredentials();
            let dados = response;

            if(this.empresaForm.controls['empresa'].value){
                dados['empresa'] = {
                    codigo: this.empresaForm.controls['empresa'].value,
                    nome: this.listaEmpresas[0]['NOME']
                };
            }

            if(this.listaEstabelecimentos[0]['CODIGO']){
                dados['estabelecimento'] = {
                    codigo: this.listaEstabelecimentos[0]['CODIGO'],
                    nome: this.listaEstabelecimentos[0]['NOME']
                };
            }
            this.authService.setToken(dados);
            this.authService.setCredentials(dados);
            this.menuService.getMenus();
            this.router.navigate(['']);
        }).catch((err) => {
            this.loading = false;
            this.loginError = true;
            this.mensagem = 'Usuário ou senha incorretos';
        });
    }

    public goLogin() : void {
        this.divLogin = !this.divLogin;
    }

    public buscaEmpresas() : void {
        this.loginService.listaEmpresas().then((result: any) => {
            if (result.length > 0) {
                this.listaEmpresas = result;
                this.empresaForm.controls['empresa'].setValue(1);
                this.buscaEstabelecimentos();
            } else {
                this.buscaEmpresas();
            }
        })
        .catch((err) => {
            console.log(err);
            this.carregando = true;
            this.messageErro = 'Ops! Ocorreu um erro no carregamento. Recarregue a página para continuar.';
        });
    }

    public buscaEstabelecimentos() : void {
        if (this.empresaForm.controls['empresa'].value == null) {
            return;
        }

        this.loginService.listaEstabelecimentos(this.empresaForm.controls['empresa'].value).then((result: any) => {
            if (result.length > 0) {
                this.listaEstabelecimentos = result;
                this.empresaForm.controls['estabelecimento'].setValue(1);
            } else {
                this.buscaEstabelecimentos();
            }
        }).catch((err) => {
            console.log(err);
            this.carregando = true;
            this.messageErro = 'Ops! Ocorreu um erro no carregamento. Recarregue a página para continuar.';
        });
    }
}
