import { NgModule } from '@angular/core';
import {
    DateToStringPipe,
    HoraToStringPipe,
} from './';

const PIPES = [DateToStringPipe, HoraToStringPipe];

@NgModule({
    exports: [...PIPES], declarations: [...PIPES]
})
export class PipesModule {

}
