<div class="principal w-full">
    <mat-card class="card-login">
        <mat-card class="topo w-full">
            <div class="logo w-full"></div>
            <button mat-fab [ngClass]="{'btn-store' : divLogin == false, 'btn-store-secondary' : divLogin == true}" (click)="divLogin = false">
                <mat-icon>store</mat-icon>
            </button>
            <button mat-fab [ngClass]="{'btn-login-topo' : divLogin == true, 'btn-login-topo-secondary' : divLogin == false}" (click)="divLogin = true" [disabled]="!empresaForm.valid">
                <mat-icon>vpn_key</mat-icon>
            </button>
        </mat-card>
        <div [ngClass]="{'div-empresarial' : divLogin == false, 'div-empresarial-none' : divLogin == true}">
            <h1 class="w-full">Empresarial</h1>
            <form name="form" [formGroup]="empresaForm" novalidate>
                <mat-form-field appearance="fill" class="input-empresas">
                    <mat-label>Empresa</mat-label>
                    <mat-select formControlName="empresa" [(value)]="selectEmpresa">
                        <mat-option *ngFor="let empresa of listaEmpresas;" [value]="empresa['codigo']" (click)="buscaEstabelecimentos()">{{empresa['nome']}}</mat-option>
                    </mat-select>
                    <mat-hint>Selecione a empresa contratante.</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="fill" class="input-empresas">
                    <mat-label>Estabelecimento</mat-label>
                    <mat-select formControlName="estabelecimento">
                        <mat-option *ngFor="let estabelecimento of listaEstabelecimentos" [value]="estabelecimento['codigo']">{{estabelecimento['nome']}}</mat-option>
                    </mat-select>
                    <mat-hint>Selecione o estabelecimento atual.</mat-hint>
                </mat-form-field>
            </form>
            <button mat-raised-button color="primary" class="w-full" (click)="goLogin()" [disabled]="!empresaForm.valid">Login
                <mat-icon matSuffix>chevron_right</mat-icon>
            </button>
        </div>
        <div [ngClass]="{'div-login' : divLogin == true, 'div-login-none' : divLogin == false}">
            <h1 class="w-full">Login</h1>
            <form class="inputsLogin" name="form" [formGroup]="loginForm" novalidate>
                <mat-form-field appearance="fill" class="input-login" (click)="loginError = false">
                    <mat-label>Login</mat-label>
                    <input matInput name="usuario" required formControlName="usuario" (keyup)="onKey($event)">
                    <mat-icon matSuffix>face</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="fill" class="input-senha" (click)="loginError = false">
                    <mat-label>Senha</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" name="senha" required formControlName="senha" (keyup)="onKey($event)">
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                </mat-form-field>
                <mat-hint class="login-error w-full" *ngIf="loginError == true"> Usuário ou senha incorretos!</mat-hint>
            </form>
            <button mat-raised-button color="primary" class="w-full" (click)="logar();" [disabled]="loading || !loginForm.valid">
                <span class="color-progress" [loadingButton]="loading">Entrar</span>
            </button>
            <label class="label-rodape w-full">Limber Software - A sua solução em turismo</label>
        </div>
    </mat-card>
</div>
